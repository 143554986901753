import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import Wave from 'react-wavify';
import { Slide } from 'react-reveal';
import TeamCard from "../components/team";
import Collapse,{ CollapseItem} from "../components/collapse";



function Team(){
        return(
        <Layout>
            <Helmet>
                <title>About Us | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Crediflow in a simple and efficient way handles both your outgoing and incoming invoices regardless of format." />
                <meta name="keywords" content="Om Crediflow | We are Crediflow" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="We are Crediflow" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="en_EN" />
                <meta property="og:url" content="http://www.crediflow.com/about-us" />
                <link rel="cannonical" href="http://www.crediflow.com/about-us" />
            </Helmet>


            <section className="bg-gray-100 pt-4">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="bg-team-banner bg-cover bg-no-repeat bg-center rounded-lg overflow-hidden">
                        <div className="w-full h-full" style={{background: "rgba(29, 95, 182, 0.05)"}}>
                            <h1 className="pt-40 pb-32  text-4xl lg:text-5xl font-bold text-white text-center">Crediflow</h1>
                            <Wave 
                                className="-mb-2"
                                fill='#F3F4F6'
                                paused={false}
                                options={{
                                height: 20,
                                amplitude: 50,
                                speed: 0.15,
                                points: 3
                                }}
                            />  
                        </div>
                    </div>
                </div>
            </section>

            {/***********  About Section - START  **********/}
            <section className="bg-gray-100 py-24">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid xl:grid-cols-2 gap-32 lg:gap-20">
                        <Slide left>
                        <div className="w-full">
                            <h2 className="title-font font-semibold text-3xl text-gray-900">Can a modern company have over 25 years of experience?</h2>
                            <p className="leading-relaxed mt-6 text-gray-600">
                                The answer is simple &#8211; YES! We have over 25 
                                years of experience and, with our deliveries to 
                                public authorities as well as to small companies, 
                                we have enormous experience in the market we operate in.
                                <br/><br/>
                                Our group today has a turnover of close to 100 million per 
                                year and has had a Triple AAA rating for several years, 
                                which means that you get a financially strong supplier 
                                who also loves technology. Our customers thus get to 
                                take part in the most modern technology available 
                                on the market such as AI, machine learning RPA etc.
                                <br/><br/>
                                We want to make your journey in digitization easy, 
                                let us take you into the future. Did you know 
                                that we also save money for our customers and partners?
                            </p>  

                            <div className="w-full mt-8">
                                <Collapse collapse_text="Policy">
                                    <CollapseItem 
                                        href="https://crediflow.se/pdf/policy/informationssakerhetspolicy" 
                                        pdf_name="Information security policy"
                                    />
                                    <CollapseItem 
                                        href="https://crediflow.se/pdf/policy/kvalitetspolicy" 
                                        pdf_name="Quality policy"
                                    />
                                    <CollapseItem 
                                        href="https://crediflow.se/pdf/policy/miljopolicy" 
                                        pdf_name="Environmental policy"
                                    />
                                </Collapse>   
                            </div>
                            <div className="w-full mt-8">
                                <Collapse collapse_text="Terms and Privacy">
                                    <CollapseItem 
                                        href="https://crediflow.se/pdf/villkor/anvandarvillkor" 
                                        pdf_name="Terms of use"
                                    />
                                    <CollapseItem 
                                        href="https://crediflow.se/pdf/villkor/integritetspolicy/" 
                                        pdf_name="Privacy policy"
                                    />
                                </Collapse>   
                            </div>
                          
                        </div>
                        </Slide>
                        <Slide right>
                            <div>
                            <div className="w-full mx-auto flex items-start justify-center bg-shape bg-no-repeat bg-contain bg-top">
                                <div className="mt-14 lg:mt-0 flex justify-center items-center" style={{width: "25rem"}}>
                                    <div className="w-10/12 transform rotate-1 sm:rotate-3 ">
                                        <div className="custom_3d_img transform -skew-x-2  sm:-skew-x-6 pr-3 pb-3 bg-gradient-to-br from-blue-400 to-blue-900" >
                                            <div className="bg-gradient-to-tl from-gray-400 to-blue-800 rounded-3xl pb-3 pt-2 pl-1 pr-2">
                                                <div className="bg-white rounded-3xl overflow-hidden" style={{height: "550px"}}>
                                                    <div className="h-full w-full flex flex-col justify-between">
                                                        <iframe src="https://bot.leadoo.com/bot/inpage.html?code=QtxTmsUN" style={{border: "none", width: "100%", height: "380px"}} title="chatbot crediflow"/>
                                                        <div className="w-full h-40 m-1.5 bg-no-repeat bg-keyboard bg-contain bg-bottom border-t border-gray-200"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </Slide>
                    </div>
                </div>
            </section>
            {/***********  About Section - END  **********/}

            <section className="bg-gray-100 py-16">
                <div className="container mx-auto px-4 xl:px-24">
                    <hr className="text-gray-200"/>
                </div>
            </section>

            {/***********  Our Team - START  **********/}
            <section className="bg-gray-100 pt-20 pb-24 ">
                <div className="container mx-auto px-4 xl:px-24">
                    <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Our team</h1>
                    <p className="mt-4 text-center text-gray-600 lg:w-1/2 mx-auto">It is us you will meet when you choose Crediflow!</p>
                </div>
            </section>

            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-12 ">
                          <TeamCard 
                            name="Ann-Kristine Pettersson"
                            title="Front-office Reception"
                            number="+46 857 860 400"
                        />
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Sales</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Anders Persson"
                            title="Sales Partner Incoming"
                            number="+46 857 860 405"
                        />
                        <TeamCard 
                            name="Christian Krutzfeldt"
                            title="Sales Partner Outgoing"
                            number="+46 42-125 453"
                        />
                    </div>
                </div>
            </section>




            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Support Stockholm</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Nicklas Nylén"
                            title="Support Stockholm"
                        />
                        <TeamCard 
                            name="Kjell Bergendahl"
                            title="Support 2-line Stockholm"
                        />
                        <TeamCard 
                            name="Adam Fins"
                            title="Support Stockholm"
                        />
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Support Helsingborg</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Felix Oxing"
                            title="Support Helsingborg"
                        />
                        <TeamCard 
                            name="Jonas Schrewelius"
                            title="Support Helsingborg"
                        />
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Project</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Anders Staaf"
                            title="Project manager"
                            number="+46 857 860 402"
                        />
                        <TeamCard 
                            name="Mari Dauner"
                            title="Product Owner CS eVoyce"
                            number="+46 857 860 424"
                        />
                        <TeamCard 
                            name="Pia Sandhagen"
                            title="Project manager"
                            number="+46 857 860 448"
                        />
                    </div>
                </div>
            </section>




            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Developer Stockholm</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Daniel S</h1>
                        </div>

                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Stefan H</h1>
                        </div>

                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Gustav E</h1>
                        </div>

                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Erik W</h1>
                        </div>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Developer abroad</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Sigitas L</h1>
                        </div>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Developer Helsingborg</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Joakim P</h1>
                        </div>
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">John T</h1>
                        </div>
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">David H</h1>
                        </div>
                        <div className="w-full rounded-lg px-4 pt-6 pb-16 bg-gradient-to-b from-white via-white to-gray-100">
                            <h1 className="text-center text-xl font-semibold text-gray-800">Elinore E</h1>
                        </div>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Economy</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Peter Larsson "
                            title="CFO"
                            number="+46 725 604 888"
                        />
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Management Sweden</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Niklas Horn Lundberg"
                            title="CEO"
                        />
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 pt-12 pb-32">
                <div className="container mx-auto px-4 xl:px-24">
                    <h3 className="text-blue-custome">Operations Manager South</h3>
                    <hr className="mt-2 text-gray-200"/>
                    <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 gap-12">
                        <TeamCard 
                            name="Georgios Vasiliagos"
                            title="Vice CEO/COO South"
                            number="+46 42-127 290"
                        />
                    </div>
                </div>
            </section>
   
        </Layout>
    );
}
export default Team;